/*
 *  =======================================
 *  PROJEKT TWORZONY ZA POMOCA
 *  PREPROCESORA SCSS
 *	JESLI CHCESZ EDYTOWAC COKOLWIEK EDYTUJ
 *  PLIKI SASS  SCSS
 *  =======================================
 */

@import '_bootstrap';
@import 'modules/_elements';
@import 'partials/_variables';

@import 'vendor/owl.carousel';
@import 'vendor/owl.theme.default';

@import 'fonts/_font-awesome';
@import 'fonts/_lemonicon';