/*
 *  ======================================= 
 *  VARIABLES | _variables
 *  =======================================  
 */
$font_directory : '../fonts/';
$font_opensans  : 'Open Sans', sans-serif;
$container_padding : 0 30px;
$header_elem_padding : (
        'size1':0 40px,
        'size2':0 21px,
        'size3':0 18px
);

$colors : (
        'black':#000000,
        'red':#dc383c,
        'lightred':#e77477,
        'lightred2':#DC4A4D,
        'yellow':#F8A427,
        'green':#97BB3F,
        'dark':#2B2A28,
        'maintext':#2B2A28,
        'midgrey':#CECECE,
        'darkgrey':#7C7C7C,
        'lightgrey':#EBEBEB,
        'almostwhite':#FDFDFD
);