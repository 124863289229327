@font-face {
  font-family: 'lemonicon';
  src:    url('#{$font_directory}lemonicon.eot?55h7a9');
  src:    url('#{$font_directory}lemonicon.eot?55h7a9#iefix') format('embedded-opentype'),
  url('#{$font_directory}lemonicon.ttf?55h7a9') format('truetype'),
  url('#{$font_directory}lemonicon.woff?55h7a9') format('woff'),
  url('#{$font_directory}lemonicon.svg?55h7a9#lemonicon') format('svg');
  font-weight: normal;
  font-style: normal;
}
i[class*="lemonicon-"]{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lemonicon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lemonicon-home:before {
  content: "\e600";
}
.lemonicon-home2:before {
  content: "\e601";
}
.lemonicon-home3:before {
  content: "\e602";
}
.lemonicon-home4:before {
  content: "\e603";
}
.lemonicon-home5:before {
  content: "\e604";
}
.lemonicon-home6:before {
  content: "\e605";
}
.lemonicon-bathtub:before {
  content: "\e606";
}
.lemonicon-toothbrush:before {
  content: "\e607";
}
.lemonicon-bed:before {
  content: "\e608";
}
.lemonicon-couch:before {
  content: "\e609";
}
.lemonicon-chair:before {
  content: "\e60a";
}
.lemonicon-city:before {
  content: "\e60b";
}
.lemonicon-apartment:before {
  content: "\e60c";
}
.lemonicon-pencil:before {
  content: "\e60d";
}
.lemonicon-pencil2:before {
  content: "\e60e";
}
.lemonicon-pen:before {
  content: "\e60f";
}
.lemonicon-pencil3:before {
  content: "\e610";
}
.lemonicon-eraser:before {
  content: "\e611";
}
.lemonicon-pencil4:before {
  content: "\e612";
}
.lemonicon-pencil5:before {
  content: "\e613";
}
.lemonicon-feather:before {
  content: "\e614";
}
.lemonicon-feather2:before {
  content: "\e615";
}
.lemonicon-feather3:before {
  content: "\e616";
}
.lemonicon-pen2:before {
  content: "\e617";
}
.lemonicon-pen-add:before {
  content: "\e618";
}
.lemonicon-pen-remove:before {
  content: "\e619";
}
.lemonicon-vector:before {
  content: "\e61a";
}
.lemonicon-pen3:before {
  content: "\e61b";
}
.lemonicon-blog:before {
  content: "\e61c";
}
.lemonicon-brush:before {
  content: "\e61d";
}
.lemonicon-brush2:before {
  content: "\e61e";
}
.lemonicon-spray:before {
  content: "\e61f";
}
.lemonicon-paint-roller:before {
  content: "\e620";
}
.lemonicon-stamp:before {
  content: "\e621";
}
.lemonicon-tape:before {
  content: "\e622";
}
.lemonicon-desk-tape:before {
  content: "\e623";
}
.lemonicon-texture:before {
  content: "\e624";
}
.lemonicon-eye-dropper:before {
  content: "\e625";
}
.lemonicon-palette:before {
  content: "\e626";
}
.lemonicon-color-sampler:before {
  content: "\e627";
}
.lemonicon-bucket:before {
  content: "\e628";
}
.lemonicon-gradient:before {
  content: "\e629";
}
.lemonicon-gradient2:before {
  content: "\e62a";
}
.lemonicon-magic-wand:before {
  content: "\e62b";
}
.lemonicon-magnet:before {
  content: "\e62c";
}
.lemonicon-pencil-ruler:before {
  content: "\e62d";
}
.lemonicon-pencil-ruler2:before {
  content: "\e62e";
}
.lemonicon-compass:before {
  content: "\e62f";
}
.lemonicon-aim:before {
  content: "\e630";
}
.lemonicon-gun:before {
  content: "\e631";
}
.lemonicon-bottle:before {
  content: "\e632";
}
.lemonicon-drop:before {
  content: "\e633";
}
.lemonicon-drop-crossed:before {
  content: "\e634";
}
.lemonicon-drop2:before {
  content: "\e635";
}
.lemonicon-snow:before {
  content: "\e636";
}
.lemonicon-snow2:before {
  content: "\e637";
}
.lemonicon-fire:before {
  content: "\e638";
}
.lemonicon-lighter:before {
  content: "\e639";
}
.lemonicon-knife:before {
  content: "\e63a";
}
.lemonicon-dagger:before {
  content: "\e63b";
}
.lemonicon-tissue:before {
  content: "\e63c";
}
.lemonicon-toilet-paper:before {
  content: "\e63d";
}
.lemonicon-poop:before {
  content: "\e63e";
}
.lemonicon-umbrella:before {
  content: "\e63f";
}
.lemonicon-umbrella2:before {
  content: "\e640";
}
.lemonicon-rain:before {
  content: "\e641";
}
.lemonicon-tornado:before {
  content: "\e642";
}
.lemonicon-wind:before {
  content: "\e643";
}
.lemonicon-fan:before {
  content: "\e644";
}
.lemonicon-contrast:before {
  content: "\e645";
}
.lemonicon-sun-small:before {
  content: "\e646";
}
.lemonicon-sun:before {
  content: "\e647";
}
.lemonicon-sun2:before {
  content: "\e648";
}
.lemonicon-moon:before {
  content: "\e649";
}
.lemonicon-cloud:before {
  content: "\e64a";
}
.lemonicon-cloud-upload:before {
  content: "\e64b";
}
.lemonicon-cloud-download:before {
  content: "\e64c";
}
.lemonicon-cloud-rain:before {
  content: "\e64d";
}
.lemonicon-cloud-hailstones:before {
  content: "\e64e";
}
.lemonicon-cloud-snow:before {
  content: "\e64f";
}
.lemonicon-cloud-windy:before {
  content: "\e650";
}
.lemonicon-sun-wind:before {
  content: "\e651";
}
.lemonicon-cloud-fog:before {
  content: "\e652";
}
.lemonicon-cloud-sun:before {
  content: "\e653";
}
.lemonicon-cloud-lightning:before {
  content: "\e654";
}
.lemonicon-cloud-sync:before {
  content: "\e655";
}
.lemonicon-cloud-lock:before {
  content: "\e656";
}
.lemonicon-cloud-gear:before {
  content: "\e657";
}
.lemonicon-cloud-alert:before {
  content: "\e658";
}
.lemonicon-cloud-check:before {
  content: "\e659";
}
.lemonicon-cloud-cross:before {
  content: "\e65a";
}
.lemonicon-cloud-crossed:before {
  content: "\e65b";
}
.lemonicon-cloud-database:before {
  content: "\e65c";
}
.lemonicon-database:before {
  content: "\e65d";
}
.lemonicon-database-add:before {
  content: "\e65e";
}
.lemonicon-database-remove:before {
  content: "\e65f";
}
.lemonicon-database-lock:before {
  content: "\e660";
}
.lemonicon-database-refresh:before {
  content: "\e661";
}
.lemonicon-database-check:before {
  content: "\e662";
}
.lemonicon-database-history:before {
  content: "\e663";
}
.lemonicon-database-upload:before {
  content: "\e664";
}
.lemonicon-database-download:before {
  content: "\e665";
}
.lemonicon-server:before {
  content: "\e666";
}
.lemonicon-shield:before {
  content: "\e667";
}
.lemonicon-shield-check:before {
  content: "\e668";
}
.lemonicon-shield-alert:before {
  content: "\e669";
}
.lemonicon-shield-cross:before {
  content: "\e66a";
}
.lemonicon-lock:before {
  content: "\e66b";
}
.lemonicon-rotation-lock:before {
  content: "\e66c";
}
.lemonicon-unlock:before {
  content: "\e66d";
}
.lemonicon-key:before {
  content: "\e66e";
}
.lemonicon-key-hole:before {
  content: "\e66f";
}
.lemonicon-toggle-off:before {
  content: "\e670";
}
.lemonicon-toggle-on:before {
  content: "\e671";
}
.lemonicon-cog:before {
  content: "\e672";
}
.lemonicon-cog2:before {
  content: "\e673";
}
.lemonicon-wrench:before {
  content: "\e674";
}
.lemonicon-screwdriver:before {
  content: "\e675";
}
.lemonicon-hammer-wrench:before {
  content: "\e676";
}
.lemonicon-hammer:before {
  content: "\e677";
}
.lemonicon-saw:before {
  content: "\e678";
}
.lemonicon-axe:before {
  content: "\e679";
}
.lemonicon-axe2:before {
  content: "\e67a";
}
.lemonicon-shovel:before {
  content: "\e67b";
}
.lemonicon-pickaxe:before {
  content: "\e67c";
}
.lemonicon-factory:before {
  content: "\e67d";
}
.lemonicon-factory2:before {
  content: "\e67e";
}
.lemonicon-recycle:before {
  content: "\e67f";
}
.lemonicon-trash:before {
  content: "\e680";
}
.lemonicon-trash2:before {
  content: "\e681";
}
.lemonicon-trash3:before {
  content: "\e682";
}
.lemonicon-broom:before {
  content: "\e683";
}
.lemonicon-game:before {
  content: "\e684";
}
.lemonicon-gamepad:before {
  content: "\e685";
}
.lemonicon-joystick:before {
  content: "\e686";
}
.lemonicon-dice:before {
  content: "\e687";
}
.lemonicon-spades:before {
  content: "\e688";
}
.lemonicon-diamonds:before {
  content: "\e689";
}
.lemonicon-clubs:before {
  content: "\e68a";
}
.lemonicon-hearts:before {
  content: "\e68b";
}
.lemonicon-heart:before {
  content: "\e68c";
}
.lemonicon-star:before {
  content: "\e68d";
}
.lemonicon-star-half:before {
  content: "\e68e";
}
.lemonicon-star-empty:before {
  content: "\e68f";
}
.lemonicon-flag:before {
  content: "\e690";
}
.lemonicon-flag2:before {
  content: "\e691";
}
.lemonicon-flag3:before {
  content: "\e692";
}
.lemonicon-mailbox-full:before {
  content: "\e693";
}
.lemonicon-mailbox-empty:before {
  content: "\e694";
}
.lemonicon-at-sign:before {
  content: "\e695";
}
.lemonicon-envelope:before {
  content: "\e696";
}
.lemonicon-envelope-open:before {
  content: "\e697";
}
.lemonicon-paperclip:before {
  content: "\e698";
}
.lemonicon-paper-plane:before {
  content: "\e699";
}
.lemonicon-reply:before {
  content: "\e69a";
}
.lemonicon-reply-all:before {
  content: "\e69b";
}
.lemonicon-inbox:before {
  content: "\e69c";
}
.lemonicon-inbox2:before {
  content: "\e69d";
}
.lemonicon-outbox:before {
  content: "\e69e";
}
.lemonicon-box:before {
  content: "\e69f";
}
.lemonicon-archive:before {
  content: "\e6a0";
}
.lemonicon-archive2:before {
  content: "\e6a1";
}
.lemonicon-drawers:before {
  content: "\e6a2";
}
.lemonicon-drawers2:before {
  content: "\e6a3";
}
.lemonicon-drawers3:before {
  content: "\e6a4";
}
.lemonicon-eye:before {
  content: "\e6a5";
}
.lemonicon-eye-crossed:before {
  content: "\e6a6";
}
.lemonicon-eye-plus:before {
  content: "\e6a7";
}
.lemonicon-eye-minus:before {
  content: "\e6a8";
}
.lemonicon-binoculars:before {
  content: "\e6a9";
}
.lemonicon-binoculars2:before {
  content: "\e6aa";
}
.lemonicon-hdd:before {
  content: "\e6ab";
}
.lemonicon-hdd-down:before {
  content: "\e6ac";
}
.lemonicon-hdd-up:before {
  content: "\e6ad";
}
.lemonicon-floppy-disk:before {
  content: "\e6ae";
}
.lemonicon-disc:before {
  content: "\e6af";
}
.lemonicon-tape2:before {
  content: "\e6b0";
}
.lemonicon-printer:before {
  content: "\e6b1";
}
.lemonicon-shredder:before {
  content: "\e6b2";
}
.lemonicon-file-empty:before {
  content: "\e6b3";
}
.lemonicon-file-add:before {
  content: "\e6b4";
}
.lemonicon-file-check:before {
  content: "\e6b5";
}
.lemonicon-file-lock:before {
  content: "\e6b6";
}
.lemonicon-files:before {
  content: "\e6b7";
}
.lemonicon-copy:before {
  content: "\e6b8";
}
.lemonicon-compare:before {
  content: "\e6b9";
}
.lemonicon-folder:before {
  content: "\e6ba";
}
.lemonicon-folder-search:before {
  content: "\e6bb";
}
.lemonicon-folder-plus:before {
  content: "\e6bc";
}
.lemonicon-folder-minus:before {
  content: "\e6bd";
}
.lemonicon-folder-download:before {
  content: "\e6be";
}
.lemonicon-folder-upload:before {
  content: "\e6bf";
}
.lemonicon-folder-star:before {
  content: "\e6c0";
}
.lemonicon-folder-heart:before {
  content: "\e6c1";
}
.lemonicon-folder-user:before {
  content: "\e6c2";
}
.lemonicon-folder-shared:before {
  content: "\e6c3";
}
.lemonicon-folder-music:before {
  content: "\e6c4";
}
.lemonicon-folder-picture:before {
  content: "\e6c5";
}
.lemonicon-folder-film:before {
  content: "\e6c6";
}
.lemonicon-scissors:before {
  content: "\e6c7";
}
.lemonicon-paste:before {
  content: "\e6c8";
}
.lemonicon-clipboard-empty:before {
  content: "\e6c9";
}
.lemonicon-clipboard-pencil:before {
  content: "\e6ca";
}
.lemonicon-clipboard-text:before {
  content: "\e6cb";
}
.lemonicon-clipboard-check:before {
  content: "\e6cc";
}
.lemonicon-clipboard-down:before {
  content: "\e6cd";
}
.lemonicon-clipboard-left:before {
  content: "\e6ce";
}
.lemonicon-clipboard-alert:before {
  content: "\e6cf";
}
.lemonicon-clipboard-user:before {
  content: "\e6d0";
}
.lemonicon-register:before {
  content: "\e6d1";
}
.lemonicon-enter:before {
  content: "\e6d2";
}
.lemonicon-exit:before {
  content: "\e6d3";
}
.lemonicon-papers:before {
  content: "\e6d4";
}
.lemonicon-news:before {
  content: "\e6d5";
}
.lemonicon-reading:before {
  content: "\e6d6";
}
.lemonicon-typewriter:before {
  content: "\e6d7";
}
.lemonicon-document:before {
  content: "\e6d8";
}
.lemonicon-document2:before {
  content: "\e6d9";
}
.lemonicon-graduation-hat:before {
  content: "\e6da";
}
.lemonicon-license:before {
  content: "\e6db";
}
.lemonicon-license2:before {
  content: "\e6dc";
}
.lemonicon-medal-empty:before {
  content: "\e6dd";
}
.lemonicon-medal-first:before {
  content: "\e6de";
}
.lemonicon-medal-second:before {
  content: "\e6df";
}
.lemonicon-medal-third:before {
  content: "\e6e0";
}
.lemonicon-podium:before {
  content: "\e6e1";
}
.lemonicon-trophy:before {
  content: "\e6e2";
}
.lemonicon-trophy2:before {
  content: "\e6e3";
}
.lemonicon-music-note:before {
  content: "\e6e4";
}
.lemonicon-music-note2:before {
  content: "\e6e5";
}
.lemonicon-music-note3:before {
  content: "\e6e6";
}
.lemonicon-playlist:before {
  content: "\e6e7";
}
.lemonicon-playlist-add:before {
  content: "\e6e8";
}
.lemonicon-guitar:before {
  content: "\e6e9";
}
.lemonicon-trumpet:before {
  content: "\e6ea";
}
.lemonicon-album:before {
  content: "\e6eb";
}
.lemonicon-shuffle:before {
  content: "\e6ec";
}
.lemonicon-repeat-one:before {
  content: "\e6ed";
}
.lemonicon-repeat:before {
  content: "\e6ee";
}
.lemonicon-headphones:before {
  content: "\e6ef";
}
.lemonicon-headset:before {
  content: "\e6f0";
}
.lemonicon-loudspeaker:before {
  content: "\e6f1";
}
.lemonicon-equalizer:before {
  content: "\e6f2";
}
.lemonicon-theater:before {
  content: "\e6f3";
}
.lemonicon-3d-glasses:before {
  content: "\e6f4";
}
.lemonicon-ticket:before {
  content: "\e6f5";
}
.lemonicon-presentation:before {
  content: "\e6f6";
}
.lemonicon-play:before {
  content: "\e6f7";
}
.lemonicon-film-play:before {
  content: "\e6f8";
}
.lemonicon-clapboard-play:before {
  content: "\e6f9";
}
.lemonicon-media:before {
  content: "\e6fa";
}
.lemonicon-film:before {
  content: "\e6fb";
}
.lemonicon-film2:before {
  content: "\e6fc";
}
.lemonicon-surveillance:before {
  content: "\e6fd";
}
.lemonicon-surveillance2:before {
  content: "\e6fe";
}
.lemonicon-camera:before {
  content: "\e6ff";
}
.lemonicon-camera-crossed:before {
  content: "\e700";
}
.lemonicon-camera-play:before {
  content: "\e701";
}
.lemonicon-time-lapse:before {
  content: "\e702";
}
.lemonicon-record:before {
  content: "\e703";
}
.lemonicon-camera2:before {
  content: "\e704";
}
.lemonicon-camera-flip:before {
  content: "\e705";
}
.lemonicon-panorama:before {
  content: "\e706";
}
.lemonicon-time-lapse2:before {
  content: "\e707";
}
.lemonicon-shutter:before {
  content: "\e708";
}
.lemonicon-shutter2:before {
  content: "\e709";
}
.lemonicon-face-detection:before {
  content: "\e70a";
}
.lemonicon-flare:before {
  content: "\e70b";
}
.lemonicon-convex:before {
  content: "\e70c";
}
.lemonicon-concave:before {
  content: "\e70d";
}
.lemonicon-picture:before {
  content: "\e70e";
}
.lemonicon-picture2:before {
  content: "\e70f";
}
.lemonicon-picture3:before {
  content: "\e710";
}
.lemonicon-pictures:before {
  content: "\e711";
}
.lemonicon-book:before {
  content: "\e712";
}
.lemonicon-audio-book:before {
  content: "\e713";
}
.lemonicon-book2:before {
  content: "\e714";
}
.lemonicon-bookmark:before {
  content: "\e715";
}
.lemonicon-bookmark2:before {
  content: "\e716";
}
.lemonicon-label:before {
  content: "\e717";
}
.lemonicon-library:before {
  content: "\e718";
}
.lemonicon-library2:before {
  content: "\e719";
}
.lemonicon-contacts:before {
  content: "\e71a";
}
.lemonicon-profile:before {
  content: "\e71b";
}
.lemonicon-portrait:before {
  content: "\e71c";
}
.lemonicon-portrait2:before {
  content: "\e71d";
}
.lemonicon-user:before {
  content: "\e71e";
}
.lemonicon-user-plus:before {
  content: "\e71f";
}
.lemonicon-user-minus:before {
  content: "\e720";
}
.lemonicon-user-lock:before {
  content: "\e721";
}
.lemonicon-users:before {
  content: "\e722";
}
.lemonicon-users2:before {
  content: "\e723";
}
.lemonicon-users-plus:before {
  content: "\e724";
}
.lemonicon-users-minus:before {
  content: "\e725";
}
.lemonicon-group-work:before {
  content: "\e726";
}
.lemonicon-woman:before {
  content: "\e727";
}
.lemonicon-man:before {
  content: "\e728";
}
.lemonicon-baby:before {
  content: "\e729";
}
.lemonicon-baby2:before {
  content: "\e72a";
}
.lemonicon-baby3:before {
  content: "\e72b";
}
.lemonicon-baby-bottle:before {
  content: "\e72c";
}
.lemonicon-walk:before {
  content: "\e72d";
}
.lemonicon-hand-waving:before {
  content: "\e72e";
}
.lemonicon-jump:before {
  content: "\e72f";
}
.lemonicon-run:before {
  content: "\e730";
}
.lemonicon-woman2:before {
  content: "\e731";
}
.lemonicon-man2:before {
  content: "\e732";
}
.lemonicon-man-woman:before {
  content: "\e733";
}
.lemonicon-height:before {
  content: "\e734";
}
.lemonicon-weight:before {
  content: "\e735";
}
.lemonicon-scale:before {
  content: "\e736";
}
.lemonicon-button:before {
  content: "\e737";
}
.lemonicon-bow-tie:before {
  content: "\e738";
}
.lemonicon-tie:before {
  content: "\e739";
}
.lemonicon-socks:before {
  content: "\e73a";
}
.lemonicon-shoe:before {
  content: "\e73b";
}
.lemonicon-shoes:before {
  content: "\e73c";
}
.lemonicon-hat:before {
  content: "\e73d";
}
.lemonicon-pants:before {
  content: "\e73e";
}
.lemonicon-shorts:before {
  content: "\e73f";
}
.lemonicon-flip-flops:before {
  content: "\e740";
}
.lemonicon-shirt:before {
  content: "\e741";
}
.lemonicon-hanger:before {
  content: "\e742";
}
.lemonicon-laundry:before {
  content: "\e743";
}
.lemonicon-store:before {
  content: "\e744";
}
.lemonicon-haircut:before {
  content: "\e745";
}
.lemonicon-store-24:before {
  content: "\e746";
}
.lemonicon-barcode:before {
  content: "\e747";
}
.lemonicon-barcode2:before {
  content: "\e748";
}
.lemonicon-barcode3:before {
  content: "\e749";
}
.lemonicon-cashier:before {
  content: "\e74a";
}
.lemonicon-bag:before {
  content: "\e74b";
}
.lemonicon-bag2:before {
  content: "\e74c";
}
.lemonicon-cart:before {
  content: "\e74d";
}
.lemonicon-cart-empty:before {
  content: "\e74e";
}
.lemonicon-cart-full:before {
  content: "\e74f";
}
.lemonicon-cart-plus:before {
  content: "\e750";
}
.lemonicon-cart-plus2:before {
  content: "\e751";
}
.lemonicon-cart-add:before {
  content: "\e752";
}
.lemonicon-cart-remove:before {
  content: "\e753";
}
.lemonicon-cart-exchange:before {
  content: "\e754";
}
.lemonicon-tag:before {
  content: "\e755";
}
.lemonicon-tags:before {
  content: "\e756";
}
.lemonicon-receipt:before {
  content: "\e757";
}
.lemonicon-wallet:before {
  content: "\e758";
}
.lemonicon-credit-card:before {
  content: "\e759";
}
.lemonicon-cash-dollar:before {
  content: "\e75a";
}
.lemonicon-cash-euro:before {
  content: "\e75b";
}
.lemonicon-cash-pound:before {
  content: "\e75c";
}
.lemonicon-cash-yen:before {
  content: "\e75d";
}
.lemonicon-bag-dollar:before {
  content: "\e75e";
}
.lemonicon-bag-euro:before {
  content: "\e75f";
}
.lemonicon-bag-pound:before {
  content: "\e760";
}
.lemonicon-bag-yen:before {
  content: "\e761";
}
.lemonicon-coin-dollar:before {
  content: "\e762";
}
.lemonicon-coin-euro:before {
  content: "\e763";
}
.lemonicon-coin-pound:before {
  content: "\e764";
}
.lemonicon-coin-yen:before {
  content: "\e765";
}
.lemonicon-calculator:before {
  content: "\e766";
}
.lemonicon-calculator2:before {
  content: "\e767";
}
.lemonicon-abacus:before {
  content: "\e768";
}
.lemonicon-vault:before {
  content: "\e769";
}
.lemonicon-telephone:before {
  content: "\e76a";
}
.lemonicon-phone-lock:before {
  content: "\e76b";
}
.lemonicon-phone-wave:before {
  content: "\e76c";
}
.lemonicon-phone-pause:before {
  content: "\e76d";
}
.lemonicon-phone-outgoing:before {
  content: "\e76e";
}
.lemonicon-phone-incoming:before {
  content: "\e76f";
}
.lemonicon-phone-in-out:before {
  content: "\e770";
}
.lemonicon-phone-error:before {
  content: "\e771";
}
.lemonicon-phone-sip:before {
  content: "\e772";
}
.lemonicon-phone-plus:before {
  content: "\e773";
}
.lemonicon-phone-minus:before {
  content: "\e774";
}
.lemonicon-voicemail:before {
  content: "\e775";
}
.lemonicon-dial:before {
  content: "\e776";
}
.lemonicon-telephone2:before {
  content: "\e777";
}
.lemonicon-pushpin:before {
  content: "\e778";
}
.lemonicon-pushpin2:before {
  content: "\e779";
}
.lemonicon-map-marker:before {
  content: "\e77a";
}
.lemonicon-map-marker-user:before {
  content: "\e77b";
}
.lemonicon-map-marker-down:before {
  content: "\e77c";
}
.lemonicon-map-marker-check:before {
  content: "\e77d";
}
.lemonicon-map-marker-crossed:before {
  content: "\e77e";
}
.lemonicon-radar:before {
  content: "\e77f";
}
.lemonicon-compass2:before {
  content: "\e780";
}
.lemonicon-map:before {
  content: "\e781";
}
.lemonicon-map2:before {
  content: "\e782";
}
.lemonicon-location:before {
  content: "\e783";
}
.lemonicon-road-sign:before {
  content: "\e784";
}
.lemonicon-calendar-empty:before {
  content: "\e785";
}
.lemonicon-calendar-check:before {
  content: "\e786";
}
.lemonicon-calendar-cross:before {
  content: "\e787";
}
.lemonicon-calendar-31:before {
  content: "\e788";
}
.lemonicon-calendar-full:before {
  content: "\e789";
}
.lemonicon-calendar-insert:before {
  content: "\e78a";
}
.lemonicon-calendar-text:before {
  content: "\e78b";
}
.lemonicon-calendar-user:before {
  content: "\e78c";
}
.lemonicon-mouse:before {
  content: "\e78d";
}
.lemonicon-mouse-left:before {
  content: "\e78e";
}
.lemonicon-mouse-right:before {
  content: "\e78f";
}
.lemonicon-mouse-both:before {
  content: "\e790";
}
.lemonicon-keyboard:before {
  content: "\e791";
}
.lemonicon-keyboard-up:before {
  content: "\e792";
}
.lemonicon-keyboard-down:before {
  content: "\e793";
}
.lemonicon-delete:before {
  content: "\e794";
}
.lemonicon-spell-check:before {
  content: "\e795";
}
.lemonicon-escape:before {
  content: "\e796";
}
.lemonicon-enter2:before {
  content: "\e797";
}
.lemonicon-screen:before {
  content: "\e798";
}
.lemonicon-aspect-ratio:before {
  content: "\e799";
}
.lemonicon-signal:before {
  content: "\e79a";
}
.lemonicon-signal-lock:before {
  content: "\e79b";
}
.lemonicon-signal-80:before {
  content: "\e79c";
}
.lemonicon-signal-60:before {
  content: "\e79d";
}
.lemonicon-signal-40:before {
  content: "\e79e";
}
.lemonicon-signal-20:before {
  content: "\e79f";
}
.lemonicon-signal-0:before {
  content: "\e7a0";
}
.lemonicon-signal-blocked:before {
  content: "\e7a1";
}
.lemonicon-sim:before {
  content: "\e7a2";
}
.lemonicon-flash-memory:before {
  content: "\e7a3";
}
.lemonicon-usb-drive:before {
  content: "\e7a4";
}
.lemonicon-phone:before {
  content: "\e7a5";
}
.lemonicon-smartphone:before {
  content: "\e7a6";
}
.lemonicon-smartphone-notification:before {
  content: "\e7a7";
}
.lemonicon-smartphone-vibration:before {
  content: "\e7a8";
}
.lemonicon-smartphone-embed:before {
  content: "\e7a9";
}
.lemonicon-smartphone-waves:before {
  content: "\e7aa";
}
.lemonicon-tablet:before {
  content: "\e7ab";
}
.lemonicon-tablet2:before {
  content: "\e7ac";
}
.lemonicon-laptop:before {
  content: "\e7ad";
}
.lemonicon-laptop-phone:before {
  content: "\e7ae";
}
.lemonicon-desktop:before {
  content: "\e7af";
}
.lemonicon-launch:before {
  content: "\e7b0";
}
.lemonicon-new-tab:before {
  content: "\e7b1";
}
.lemonicon-window:before {
  content: "\e7b2";
}
.lemonicon-cable:before {
  content: "\e7b3";
}
.lemonicon-cable2:before {
  content: "\e7b4";
}
.lemonicon-tv:before {
  content: "\e7b5";
}
.lemonicon-radio:before {
  content: "\e7b6";
}
.lemonicon-remote-control:before {
  content: "\e7b7";
}
.lemonicon-power-switch:before {
  content: "\e7b8";
}
.lemonicon-power:before {
  content: "\e7b9";
}
.lemonicon-power-crossed:before {
  content: "\e7ba";
}
.lemonicon-flash-auto:before {
  content: "\e7bb";
}
.lemonicon-lamp:before {
  content: "\e7bc";
}
.lemonicon-flashlight:before {
  content: "\e7bd";
}
.lemonicon-lampshade:before {
  content: "\e7be";
}
.lemonicon-cord:before {
  content: "\e7bf";
}
.lemonicon-outlet:before {
  content: "\e7c0";
}
.lemonicon-battery-power:before {
  content: "\e7c1";
}
.lemonicon-battery-empty:before {
  content: "\e7c2";
}
.lemonicon-battery-alert:before {
  content: "\e7c3";
}
.lemonicon-battery-error:before {
  content: "\e7c4";
}
.lemonicon-battery-low1:before {
  content: "\e7c5";
}
.lemonicon-battery-low2:before {
  content: "\e7c6";
}
.lemonicon-battery-low3:before {
  content: "\e7c7";
}
.lemonicon-battery-mid1:before {
  content: "\e7c8";
}
.lemonicon-battery-mid2:before {
  content: "\e7c9";
}
.lemonicon-battery-mid3:before {
  content: "\e7ca";
}
.lemonicon-battery-full:before {
  content: "\e7cb";
}
.lemonicon-battery-charging:before {
  content: "\e7cc";
}
.lemonicon-battery-charging2:before {
  content: "\e7cd";
}
.lemonicon-battery-charging3:before {
  content: "\e7ce";
}
.lemonicon-battery-charging4:before {
  content: "\e7cf";
}
.lemonicon-battery-charging5:before {
  content: "\e7d0";
}
.lemonicon-battery-charging6:before {
  content: "\e7d1";
}
.lemonicon-battery-charging7:before {
  content: "\e7d2";
}
.lemonicon-chip:before {
  content: "\e7d3";
}
.lemonicon-chip-x64:before {
  content: "\e7d4";
}
.lemonicon-chip-x86:before {
  content: "\e7d5";
}
.lemonicon-bubble:before {
  content: "\e7d6";
}
.lemonicon-bubbles:before {
  content: "\e7d7";
}
.lemonicon-bubble-dots:before {
  content: "\e7d8";
}
.lemonicon-bubble-alert:before {
  content: "\e7d9";
}
.lemonicon-bubble-question:before {
  content: "\e7da";
}
.lemonicon-bubble-text:before {
  content: "\e7db";
}
.lemonicon-bubble-pencil:before {
  content: "\e7dc";
}
.lemonicon-bubble-picture:before {
  content: "\e7dd";
}
.lemonicon-bubble-video:before {
  content: "\e7de";
}
.lemonicon-bubble-user:before {
  content: "\e7df";
}
.lemonicon-bubble-quote:before {
  content: "\e7e0";
}
.lemonicon-bubble-heart:before {
  content: "\e7e1";
}
.lemonicon-bubble-emoticon:before {
  content: "\e7e2";
}
.lemonicon-bubble-attachment:before {
  content: "\e7e3";
}
.lemonicon-phone-bubble:before {
  content: "\e7e4";
}
.lemonicon-quote-open:before {
  content: "\e7e5";
}
.lemonicon-quote-close:before {
  content: "\e7e6";
}
.lemonicon-dna:before {
  content: "\e7e7";
}
.lemonicon-heart-pulse:before {
  content: "\e7e8";
}
.lemonicon-pulse:before {
  content: "\e7e9";
}
.lemonicon-syringe:before {
  content: "\e7ea";
}
.lemonicon-pills:before {
  content: "\e7eb";
}
.lemonicon-first-aid:before {
  content: "\e7ec";
}
.lemonicon-lifebuoy:before {
  content: "\e7ed";
}
.lemonicon-bandage:before {
  content: "\e7ee";
}
.lemonicon-bandages:before {
  content: "\e7ef";
}
.lemonicon-thermometer:before {
  content: "\e7f0";
}
.lemonicon-microscope:before {
  content: "\e7f1";
}
.lemonicon-brain:before {
  content: "\e7f2";
}
.lemonicon-beaker:before {
  content: "\e7f3";
}
.lemonicon-skull:before {
  content: "\e7f4";
}
.lemonicon-bone:before {
  content: "\e7f5";
}
.lemonicon-construction:before {
  content: "\e7f6";
}
.lemonicon-construction-cone:before {
  content: "\e7f7";
}
.lemonicon-pie-chart:before {
  content: "\e7f8";
}
.lemonicon-pie-chart2:before {
  content: "\e7f9";
}
.lemonicon-graph:before {
  content: "\e7fa";
}
.lemonicon-chart-growth:before {
  content: "\e7fb";
}
.lemonicon-chart-bars:before {
  content: "\e7fc";
}
.lemonicon-chart-settings:before {
  content: "\e7fd";
}
.lemonicon-cake:before {
  content: "\e7fe";
}
.lemonicon-gift:before {
  content: "\e7ff";
}
.lemonicon-balloon:before {
  content: "\e800";
}
.lemonicon-rank:before {
  content: "\e801";
}
.lemonicon-rank2:before {
  content: "\e802";
}
.lemonicon-rank3:before {
  content: "\e803";
}
.lemonicon-crown:before {
  content: "\e804";
}
.lemonicon-lotus:before {
  content: "\e805";
}
.lemonicon-diamond:before {
  content: "\e806";
}
.lemonicon-diamond2:before {
  content: "\e807";
}
.lemonicon-diamond3:before {
  content: "\e808";
}
.lemonicon-diamond4:before {
  content: "\e809";
}
.lemonicon-linearicons:before {
  content: "\e80a";
}
.lemonicon-teacup:before {
  content: "\e80b";
}
.lemonicon-teapot:before {
  content: "\e80c";
}
.lemonicon-glass:before {
  content: "\e80d";
}
.lemonicon-bottle2:before {
  content: "\e80e";
}
.lemonicon-glass-cocktail:before {
  content: "\e80f";
}
.lemonicon-glass2:before {
  content: "\e810";
}
.lemonicon-dinner:before {
  content: "\e811";
}
.lemonicon-dinner2:before {
  content: "\e812";
}
.lemonicon-chef:before {
  content: "\e813";
}
.lemonicon-scale2:before {
  content: "\e814";
}
.lemonicon-egg:before {
  content: "\e815";
}
.lemonicon-egg2:before {
  content: "\e816";
}
.lemonicon-eggs:before {
  content: "\e817";
}
.lemonicon-platter:before {
  content: "\e818";
}
.lemonicon-steak:before {
  content: "\e819";
}
.lemonicon-hamburger:before {
  content: "\e81a";
}
.lemonicon-hotdog:before {
  content: "\e81b";
}
.lemonicon-pizza:before {
  content: "\e81c";
}
.lemonicon-sausage:before {
  content: "\e81d";
}
.lemonicon-chicken:before {
  content: "\e81e";
}
.lemonicon-fish:before {
  content: "\e81f";
}
.lemonicon-carrot:before {
  content: "\e820";
}
.lemonicon-cheese:before {
  content: "\e821";
}
.lemonicon-bread:before {
  content: "\e822";
}
.lemonicon-ice-cream:before {
  content: "\e823";
}
.lemonicon-ice-cream2:before {
  content: "\e824";
}
.lemonicon-candy:before {
  content: "\e825";
}
.lemonicon-lollipop:before {
  content: "\e826";
}
.lemonicon-coffee-bean:before {
  content: "\e827";
}
.lemonicon-coffee-cup:before {
  content: "\e828";
}
.lemonicon-cherry:before {
  content: "\e829";
}
.lemonicon-grapes:before {
  content: "\e82a";
}
.lemonicon-citrus:before {
  content: "\e82b";
}
.lemonicon-apple:before {
  content: "\e82c";
}
.lemonicon-leaf:before {
  content: "\e82d";
}
.lemonicon-landscape:before {
  content: "\e82e";
}
.lemonicon-pine-tree:before {
  content: "\e82f";
}
.lemonicon-tree:before {
  content: "\e830";
}
.lemonicon-cactus:before {
  content: "\e831";
}
.lemonicon-paw:before {
  content: "\e832";
}
.lemonicon-footprint:before {
  content: "\e833";
}
.lemonicon-speed-slow:before {
  content: "\e834";
}
.lemonicon-speed-medium:before {
  content: "\e835";
}
.lemonicon-speed-fast:before {
  content: "\e836";
}
.lemonicon-rocket:before {
  content: "\e837";
}
.lemonicon-hammer2:before {
  content: "\e838";
}
.lemonicon-balance:before {
  content: "\e839";
}
.lemonicon-briefcase:before {
  content: "\e83a";
}
.lemonicon-luggage-weight:before {
  content: "\e83b";
}
.lemonicon-dolly:before {
  content: "\e83c";
}
.lemonicon-plane:before {
  content: "\e83d";
}
.lemonicon-plane-crossed:before {
  content: "\e83e";
}
.lemonicon-helicopter:before {
  content: "\e83f";
}
.lemonicon-traffic-lights:before {
  content: "\e840";
}
.lemonicon-siren:before {
  content: "\e841";
}
.lemonicon-road:before {
  content: "\e842";
}
.lemonicon-engine:before {
  content: "\e843";
}
.lemonicon-oil-pressure:before {
  content: "\e844";
}
.lemonicon-coolant-temperature:before {
  content: "\e845";
}
.lemonicon-car-battery:before {
  content: "\e846";
}
.lemonicon-gas:before {
  content: "\e847";
}
.lemonicon-gallon:before {
  content: "\e848";
}
.lemonicon-transmission:before {
  content: "\e849";
}
.lemonicon-car:before {
  content: "\e84a";
}
.lemonicon-car-wash:before {
  content: "\e84b";
}
.lemonicon-car-wash2:before {
  content: "\e84c";
}
.lemonicon-bus:before {
  content: "\e84d";
}
.lemonicon-bus2:before {
  content: "\e84e";
}
.lemonicon-car2:before {
  content: "\e84f";
}
.lemonicon-parking:before {
  content: "\e850";
}
.lemonicon-car-lock:before {
  content: "\e851";
}
.lemonicon-taxi:before {
  content: "\e852";
}
.lemonicon-car-siren:before {
  content: "\e853";
}
.lemonicon-car-wash3:before {
  content: "\e854";
}
.lemonicon-car-wash4:before {
  content: "\e855";
}
.lemonicon-ambulance:before {
  content: "\e856";
}
.lemonicon-truck:before {
  content: "\e857";
}
.lemonicon-trailer:before {
  content: "\e858";
}
.lemonicon-scale-truck:before {
  content: "\e859";
}
.lemonicon-train:before {
  content: "\e85a";
}
.lemonicon-ship:before {
  content: "\e85b";
}
.lemonicon-ship2:before {
  content: "\e85c";
}
.lemonicon-anchor:before {
  content: "\e85d";
}
.lemonicon-boat:before {
  content: "\e85e";
}
.lemonicon-bicycle:before {
  content: "\e85f";
}
.lemonicon-bicycle2:before {
  content: "\e860";
}
.lemonicon-dumbbell:before {
  content: "\e861";
}
.lemonicon-bench-press:before {
  content: "\e862";
}
.lemonicon-swim:before {
  content: "\e863";
}
.lemonicon-football:before {
  content: "\e864";
}
.lemonicon-baseball-bat:before {
  content: "\e865";
}
.lemonicon-baseball:before {
  content: "\e866";
}
.lemonicon-tennis:before {
  content: "\e867";
}
.lemonicon-tennis2:before {
  content: "\e868";
}
.lemonicon-ping-pong:before {
  content: "\e869";
}
.lemonicon-hockey:before {
  content: "\e86a";
}
.lemonicon-8ball:before {
  content: "\e86b";
}
.lemonicon-bowling:before {
  content: "\e86c";
}
.lemonicon-bowling-pins:before {
  content: "\e86d";
}
.lemonicon-golf:before {
  content: "\e86e";
}
.lemonicon-golf2:before {
  content: "\e86f";
}
.lemonicon-archery:before {
  content: "\e870";
}
.lemonicon-slingshot:before {
  content: "\e871";
}
.lemonicon-soccer:before {
  content: "\e872";
}
.lemonicon-basketball:before {
  content: "\e873";
}
.lemonicon-cube:before {
  content: "\e874";
}
.lemonicon-3d-rotate:before {
  content: "\e875";
}
.lemonicon-puzzle:before {
  content: "\e876";
}
.lemonicon-glasses:before {
  content: "\e877";
}
.lemonicon-glasses2:before {
  content: "\e878";
}
.lemonicon-accessibility:before {
  content: "\e879";
}
.lemonicon-wheelchair:before {
  content: "\e87a";
}
.lemonicon-wall:before {
  content: "\e87b";
}
.lemonicon-fence:before {
  content: "\e87c";
}
.lemonicon-wall2:before {
  content: "\e87d";
}
.lemonicon-icons:before {
  content: "\e87e";
}
.lemonicon-resize-handle:before {
  content: "\e87f";
}
.lemonicon-icons2:before {
  content: "\e880";
}
.lemonicon-select:before {
  content: "\e881";
}
.lemonicon-select2:before {
  content: "\e882";
}
.lemonicon-site-map:before {
  content: "\e883";
}
.lemonicon-earth:before {
  content: "\e884";
}
.lemonicon-earth-lock:before {
  content: "\e885";
}
.lemonicon-network:before {
  content: "\e886";
}
.lemonicon-network-lock:before {
  content: "\e887";
}
.lemonicon-planet:before {
  content: "\e888";
}
.lemonicon-happy:before {
  content: "\e889";
}
.lemonicon-smile:before {
  content: "\e88a";
}
.lemonicon-grin:before {
  content: "\e88b";
}
.lemonicon-tongue:before {
  content: "\e88c";
}
.lemonicon-sad:before {
  content: "\e88d";
}
.lemonicon-wink:before {
  content: "\e88e";
}
.lemonicon-dream:before {
  content: "\e88f";
}
.lemonicon-shocked:before {
  content: "\e890";
}
.lemonicon-shocked2:before {
  content: "\e891";
}
.lemonicon-tongue2:before {
  content: "\e892";
}
.lemonicon-neutral:before {
  content: "\e893";
}
.lemonicon-happy-grin:before {
  content: "\e894";
}
.lemonicon-cool:before {
  content: "\e895";
}
.lemonicon-mad:before {
  content: "\e896";
}
.lemonicon-grin-evil:before {
  content: "\e897";
}
.lemonicon-evil:before {
  content: "\e898";
}
.lemonicon-wow:before {
  content: "\e899";
}
.lemonicon-annoyed:before {
  content: "\e89a";
}
.lemonicon-wondering:before {
  content: "\e89b";
}
.lemonicon-confused:before {
  content: "\e89c";
}
.lemonicon-zipped:before {
  content: "\e89d";
}
.lemonicon-grumpy:before {
  content: "\e89e";
}
.lemonicon-mustache:before {
  content: "\e89f";
}
.lemonicon-tombstone-hipster:before {
  content: "\e8a0";
}
.lemonicon-tombstone:before {
  content: "\e8a1";
}
.lemonicon-ghost:before {
  content: "\e8a2";
}
.lemonicon-ghost-hipster:before {
  content: "\e8a3";
}
.lemonicon-halloween:before {
  content: "\e8a4";
}
.lemonicon-christmas:before {
  content: "\e8a5";
}
.lemonicon-easter-egg:before {
  content: "\e8a6";
}
.lemonicon-mustache2:before {
  content: "\e8a7";
}
.lemonicon-mustache-glasses:before {
  content: "\e8a8";
}
.lemonicon-pipe:before {
  content: "\e8a9";
}
.lemonicon-alarm:before {
  content: "\e8aa";
}
.lemonicon-alarm-add:before {
  content: "\e8ab";
}
.lemonicon-alarm-snooze:before {
  content: "\e8ac";
}
.lemonicon-alarm-ringing:before {
  content: "\e8ad";
}
.lemonicon-bullhorn:before {
  content: "\e8ae";
}
.lemonicon-hearing:before {
  content: "\e8af";
}
.lemonicon-volume-high:before {
  content: "\e8b0";
}
.lemonicon-volume-medium:before {
  content: "\e8b1";
}
.lemonicon-volume-low:before {
  content: "\e8b2";
}
.lemonicon-volume:before {
  content: "\e8b3";
}
.lemonicon-mute:before {
  content: "\e8b4";
}
.lemonicon-lan:before {
  content: "\e8b5";
}
.lemonicon-lan2:before {
  content: "\e8b6";
}
.lemonicon-wifi:before {
  content: "\e8b7";
}
.lemonicon-wifi-lock:before {
  content: "\e8b8";
}
.lemonicon-wifi-blocked:before {
  content: "\e8b9";
}
.lemonicon-wifi-mid:before {
  content: "\e8ba";
}
.lemonicon-wifi-low:before {
  content: "\e8bb";
}
.lemonicon-wifi-low2:before {
  content: "\e8bc";
}
.lemonicon-wifi-alert:before {
  content: "\e8bd";
}
.lemonicon-wifi-alert-mid:before {
  content: "\e8be";
}
.lemonicon-wifi-alert-low:before {
  content: "\e8bf";
}
.lemonicon-wifi-alert-low2:before {
  content: "\e8c0";
}
.lemonicon-stream:before {
  content: "\e8c1";
}
.lemonicon-stream-check:before {
  content: "\e8c2";
}
.lemonicon-stream-error:before {
  content: "\e8c3";
}
.lemonicon-stream-alert:before {
  content: "\e8c4";
}
.lemonicon-communication:before {
  content: "\e8c5";
}
.lemonicon-communication-crossed:before {
  content: "\e8c6";
}
.lemonicon-broadcast:before {
  content: "\e8c7";
}
.lemonicon-antenna:before {
  content: "\e8c8";
}
.lemonicon-satellite:before {
  content: "\e8c9";
}
.lemonicon-satellite2:before {
  content: "\e8ca";
}
.lemonicon-mic:before {
  content: "\e8cb";
}
.lemonicon-mic-mute:before {
  content: "\e8cc";
}
.lemonicon-mic2:before {
  content: "\e8cd";
}
.lemonicon-spotlights:before {
  content: "\e8ce";
}
.lemonicon-hourglass:before {
  content: "\e8cf";
}
.lemonicon-loading:before {
  content: "\e8d0";
}
.lemonicon-loading2:before {
  content: "\e8d1";
}
.lemonicon-loading3:before {
  content: "\e8d2";
}
.lemonicon-refresh:before {
  content: "\e8d3";
}
.lemonicon-refresh2:before {
  content: "\e8d4";
}
.lemonicon-undo:before {
  content: "\e8d5";
}
.lemonicon-redo:before {
  content: "\e8d6";
}
.lemonicon-jump2:before {
  content: "\e8d7";
}
.lemonicon-undo2:before {
  content: "\e8d8";
}
.lemonicon-redo2:before {
  content: "\e8d9";
}
.lemonicon-sync:before {
  content: "\e8da";
}
.lemonicon-repeat-one2:before {
  content: "\e8db";
}
.lemonicon-sync-crossed:before {
  content: "\e8dc";
}
.lemonicon-sync2:before {
  content: "\e8dd";
}
.lemonicon-repeat-one3:before {
  content: "\e8de";
}
.lemonicon-sync-crossed2:before {
  content: "\e8df";
}
.lemonicon-return:before {
  content: "\e8e0";
}
.lemonicon-return2:before {
  content: "\e8e1";
}
.lemonicon-refund:before {
  content: "\e8e2";
}
.lemonicon-history:before {
  content: "\e8e3";
}
.lemonicon-history2:before {
  content: "\e8e4";
}
.lemonicon-self-timer:before {
  content: "\e8e5";
}
.lemonicon-clock:before {
  content: "\e8e6";
}
.lemonicon-clock2:before {
  content: "\e8e7";
}
.lemonicon-clock3:before {
  content: "\e8e8";
}
.lemonicon-watch:before {
  content: "\e8e9";
}
.lemonicon-alarm2:before {
  content: "\e8ea";
}
.lemonicon-alarm-add2:before {
  content: "\e8eb";
}
.lemonicon-alarm-remove:before {
  content: "\e8ec";
}
.lemonicon-alarm-check:before {
  content: "\e8ed";
}
.lemonicon-alarm-error:before {
  content: "\e8ee";
}
.lemonicon-timer:before {
  content: "\e8ef";
}
.lemonicon-timer-crossed:before {
  content: "\e8f0";
}
.lemonicon-timer2:before {
  content: "\e8f1";
}
.lemonicon-timer-crossed2:before {
  content: "\e8f2";
}
.lemonicon-download:before {
  content: "\e8f3";
}
.lemonicon-upload:before {
  content: "\e8f4";
}
.lemonicon-download2:before {
  content: "\e8f5";
}
.lemonicon-upload2:before {
  content: "\e8f6";
}
.lemonicon-enter-up:before {
  content: "\e8f7";
}
.lemonicon-enter-down:before {
  content: "\e8f8";
}
.lemonicon-enter-left:before {
  content: "\e8f9";
}
.lemonicon-enter-right:before {
  content: "\e8fa";
}
.lemonicon-exit-up:before {
  content: "\e8fb";
}
.lemonicon-exit-down:before {
  content: "\e8fc";
}
.lemonicon-exit-left:before {
  content: "\e8fd";
}
.lemonicon-exit-right:before {
  content: "\e8fe";
}
.lemonicon-enter-up2:before {
  content: "\e8ff";
}
.lemonicon-enter-down2:before {
  content: "\e914";
}
.lemonicon-enter-vertical:before {
  content: "\e915";
}
.lemonicon-enter-left2:before {
  content: "\e916";
}
.lemonicon-enter-right2:before {
  content: "\e917";
}
.lemonicon-enter-horizontal:before {
  content: "\e918";
}
.lemonicon-exit-up2:before {
  content: "\e919";
}
.lemonicon-exit-down2:before {
  content: "\e91a";
}
.lemonicon-exit-left2:before {
  content: "\e91b";
}
.lemonicon-exit-right2:before {
  content: "\e91c";
}
.lemonicon-cli:before {
  content: "\e91d";
}
.lemonicon-bug:before {
  content: "\e91e";
}
.lemonicon-code:before {
  content: "\e91f";
}
.lemonicon-file-code:before {
  content: "\e920";
}
.lemonicon-file-image:before {
  content: "\e921";
}
.lemonicon-file-zip:before {
  content: "\e922";
}
.lemonicon-file-audio:before {
  content: "\e923";
}
.lemonicon-file-video:before {
  content: "\e924";
}
.lemonicon-file-preview:before {
  content: "\e925";
}
.lemonicon-file-charts:before {
  content: "\e926";
}
.lemonicon-file-stats:before {
  content: "\e927";
}
.lemonicon-file-spreadsheet:before {
  content: "\e928";
}
.lemonicon-link:before {
  content: "\e929";
}
.lemonicon-unlink:before {
  content: "\e92a";
}
.lemonicon-link2:before {
  content: "\e92b";
}
.lemonicon-unlink2:before {
  content: "\e92c";
}
.lemonicon-thumbs-up:before {
  content: "\e92d";
}
.lemonicon-thumbs-down:before {
  content: "\e92e";
}
.lemonicon-thumbs-up2:before {
  content: "\e92f";
}
.lemonicon-thumbs-down2:before {
  content: "\e930";
}
.lemonicon-thumbs-up3:before {
  content: "\e931";
}
.lemonicon-thumbs-down3:before {
  content: "\e932";
}
.lemonicon-share:before {
  content: "\e933";
}
.lemonicon-share2:before {
  content: "\e934";
}
.lemonicon-share3:before {
  content: "\e935";
}
.lemonicon-magnifier:before {
  content: "\e936";
}
.lemonicon-file-search:before {
  content: "\e937";
}
.lemonicon-find-replace:before {
  content: "\e938";
}
.lemonicon-zoom-in:before {
  content: "\e939";
}
.lemonicon-zoom-out:before {
  content: "\e93a";
}
.lemonicon-loupe:before {
  content: "\e93b";
}
.lemonicon-loupe-zoom-in:before {
  content: "\e93c";
}
.lemonicon-loupe-zoom-out:before {
  content: "\e93d";
}
.lemonicon-cross:before {
  content: "\e93e";
}
.lemonicon-menu:before {
  content: "\e93f";
}
.lemonicon-list:before {
  content: "\e940";
}
.lemonicon-list2:before {
  content: "\e941";
}
.lemonicon-list3:before {
  content: "\e942";
}
.lemonicon-menu2:before {
  content: "\e943";
}
.lemonicon-list4:before {
  content: "\e944";
}
.lemonicon-menu3:before {
  content: "\e945";
}
.lemonicon-exclamation:before {
  content: "\e946";
}
.lemonicon-question:before {
  content: "\e947";
}
.lemonicon-check:before {
  content: "\e948";
}
.lemonicon-cross2:before {
  content: "\e949";
}
.lemonicon-plus:before {
  content: "\e94a";
}
.lemonicon-minus:before {
  content: "\e94b";
}
.lemonicon-percent:before {
  content: "\e94c";
}
.lemonicon-chevron-up:before {
  content: "\e94d";
}
.lemonicon-chevron-down:before {
  content: "\e94e";
}
.lemonicon-chevron-left:before {
  content: "\e94f";
}
.lemonicon-chevron-right:before {
  content: "\e950";
}
.lemonicon-chevrons-expand-vertical:before {
  content: "\e951";
}
.lemonicon-chevrons-expand-horizontal:before {
  content: "\e952";
}
.lemonicon-chevrons-contract-vertical:before {
  content: "\e953";
}
.lemonicon-chevrons-contract-horizontal:before {
  content: "\e954";
}
.lemonicon-arrow-up:before {
  content: "\e955";
}
.lemonicon-arrow-down:before {
  content: "\e956";
}
.lemonicon-arrow-left:before {
  content: "\e957";
}
.lemonicon-arrow-right:before {
  content: "\e958";
}
.lemonicon-arrow-up-right:before {
  content: "\e959";
}
.lemonicon-arrows-merge:before {
  content: "\e95a";
}
.lemonicon-arrows-split:before {
  content: "\e95b";
}
.lemonicon-arrow-divert:before {
  content: "\e95c";
}
.lemonicon-arrow-return:before {
  content: "\e95d";
}
.lemonicon-expand:before {
  content: "\e95e";
}
.lemonicon-contract:before {
  content: "\e95f";
}
.lemonicon-expand2:before {
  content: "\e960";
}
.lemonicon-contract2:before {
  content: "\e961";
}
.lemonicon-move:before {
  content: "\e962";
}
.lemonicon-tab:before {
  content: "\e963";
}
.lemonicon-arrow-wave:before {
  content: "\e964";
}
.lemonicon-expand3:before {
  content: "\e965";
}
.lemonicon-expand4:before {
  content: "\e966";
}
.lemonicon-contract3:before {
  content: "\e967";
}
.lemonicon-notification:before {
  content: "\e968";
}
.lemonicon-warning:before {
  content: "\e969";
}
.lemonicon-notification-circle:before {
  content: "\e96a";
}
.lemonicon-question-circle:before {
  content: "\e96b";
}
.lemonicon-menu-circle:before {
  content: "\e96c";
}
.lemonicon-checkmark-circle:before {
  content: "\e96d";
}
.lemonicon-cross-circle:before {
  content: "\e96e";
}
.lemonicon-plus-circle:before {
  content: "\e96f";
}
.lemonicon-circle-minus:before {
  content: "\e970";
}
.lemonicon-percent-circle:before {
  content: "\e971";
}
.lemonicon-arrow-up-circle:before {
  content: "\e972";
}
.lemonicon-arrow-down-circle:before {
  content: "\e973";
}
.lemonicon-arrow-left-circle:before {
  content: "\e974";
}
.lemonicon-arrow-right-circle:before {
  content: "\e975";
}
.lemonicon-chevron-up-circle:before {
  content: "\e976";
}
.lemonicon-chevron-down-circle:before {
  content: "\e977";
}
.lemonicon-chevron-left-circle:before {
  content: "\e978";
}
.lemonicon-chevron-right-circle:before {
  content: "\e979";
}
.lemonicon-backward-circle:before {
  content: "\e97a";
}
.lemonicon-first-circle:before {
  content: "\e97b";
}
.lemonicon-previous-circle:before {
  content: "\e97c";
}
.lemonicon-stop-circle:before {
  content: "\e97d";
}
.lemonicon-play-circle:before {
  content: "\e97e";
}
.lemonicon-pause-circle:before {
  content: "\e97f";
}
.lemonicon-next-circle:before {
  content: "\e980";
}
.lemonicon-last-circle:before {
  content: "\e981";
}
.lemonicon-forward-circle:before {
  content: "\e982";
}
.lemonicon-eject-circle:before {
  content: "\e983";
}
.lemonicon-crop:before {
  content: "\e984";
}
.lemonicon-frame-expand:before {
  content: "\e985";
}
.lemonicon-frame-contract:before {
  content: "\e986";
}
.lemonicon-focus:before {
  content: "\e987";
}
.lemonicon-transform:before {
  content: "\e988";
}
.lemonicon-grid:before {
  content: "\e989";
}
.lemonicon-grid-crossed:before {
  content: "\e98a";
}
.lemonicon-layers:before {
  content: "\e98b";
}
.lemonicon-layers-crossed:before {
  content: "\e98c";
}
.lemonicon-toggle:before {
  content: "\e98d";
}
.lemonicon-rulers:before {
  content: "\e98e";
}
.lemonicon-ruler:before {
  content: "\e98f";
}
.lemonicon-funnel:before {
  content: "\e990";
}
.lemonicon-flip-horizontal:before {
  content: "\e991";
}
.lemonicon-flip-vertical:before {
  content: "\e992";
}
.lemonicon-flip-horizontal2:before {
  content: "\e993";
}
.lemonicon-flip-vertical2:before {
  content: "\e994";
}
.lemonicon-angle:before {
  content: "\e995";
}
.lemonicon-angle2:before {
  content: "\e996";
}
.lemonicon-subtract:before {
  content: "\e997";
}
.lemonicon-combine:before {
  content: "\e998";
}
.lemonicon-intersect:before {
  content: "\e999";
}
.lemonicon-exclude:before {
  content: "\e99a";
}
.lemonicon-align-center-vertical:before {
  content: "\e99b";
}
.lemonicon-align-right:before {
  content: "\e99c";
}
.lemonicon-align-bottom:before {
  content: "\e99d";
}
.lemonicon-align-left:before {
  content: "\e99e";
}
.lemonicon-align-center-horizontal:before {
  content: "\e99f";
}
.lemonicon-align-top:before {
  content: "\e9a0";
}
.lemonicon-square:before {
  content: "\e9a1";
}
.lemonicon-plus-square:before {
  content: "\e9a2";
}
.lemonicon-minus-square:before {
  content: "\e9a3";
}
.lemonicon-percent-square:before {
  content: "\e9a4";
}
.lemonicon-arrow-up-square:before {
  content: "\e9a5";
}
.lemonicon-arrow-down-square:before {
  content: "\e9a6";
}
.lemonicon-arrow-left-square:before {
  content: "\e9a7";
}
.lemonicon-arrow-right-square:before {
  content: "\e9a8";
}
.lemonicon-chevron-up-square:before {
  content: "\e9a9";
}
.lemonicon-chevron-down-square:before {
  content: "\e9aa";
}
.lemonicon-chevron-left-square:before {
  content: "\e9ab";
}
.lemonicon-chevron-right-square:before {
  content: "\e9ac";
}
.lemonicon-check-square:before {
  content: "\e9ad";
}
.lemonicon-cross-square:before {
  content: "\e9ae";
}
.lemonicon-menu-square:before {
  content: "\e9af";
}
.lemonicon-prohibited:before {
  content: "\e9b0";
}
.lemonicon-circle:before {
  content: "\e9b1";
}
.lemonicon-radio-button:before {
  content: "\e9b2";
}
.lemonicon-ligature:before {
  content: "\e9b3";
}
.lemonicon-text-format:before {
  content: "\e9b4";
}
.lemonicon-text-format-remove:before {
  content: "\e9b5";
}
.lemonicon-text-size:before {
  content: "\e9b6";
}
.lemonicon-bold:before {
  content: "\e9b7";
}
.lemonicon-italic:before {
  content: "\e9b8";
}
.lemonicon-underline:before {
  content: "\e9b9";
}
.lemonicon-strikethrough:before {
  content: "\e9ba";
}
.lemonicon-highlight:before {
  content: "\e9bb";
}
.lemonicon-text-align-left:before {
  content: "\e9bc";
}
.lemonicon-text-align-center:before {
  content: "\e9bd";
}
.lemonicon-text-align-right:before {
  content: "\e9be";
}
.lemonicon-text-align-justify:before {
  content: "\e9bf";
}
.lemonicon-line-spacing:before {
  content: "\e9c0";
}
.lemonicon-indent-increase:before {
  content: "\e9c1";
}
.lemonicon-indent-decrease:before {
  content: "\e9c2";
}
.lemonicon-text-wrap:before {
  content: "\e9c3";
}
.lemonicon-pilcrow:before {
  content: "\e9c4";
}
.lemonicon-direction-ltr:before {
  content: "\e9c5";
}
.lemonicon-direction-rtl:before {
  content: "\e9c6";
}
.lemonicon-page-break:before {
  content: "\e9c7";
}
.lemonicon-page-break2:before {
  content: "\e9c8";
}
.lemonicon-sort-alpha-asc:before {
  content: "\e9c9";
}
.lemonicon-sort-alpha-desc:before {
  content: "\e9ca";
}
.lemonicon-sort-numeric-asc:before {
  content: "\e9cb";
}
.lemonicon-sort-numeric-desc:before {
  content: "\e9cc";
}
.lemonicon-sort-amount-asc:before {
  content: "\e9cd";
}
.lemonicon-sort-amount-desc:before {
  content: "\e9ce";
}
.lemonicon-sort-time-asc:before {
  content: "\e9cf";
}
.lemonicon-sort-time-desc:before {
  content: "\e9d0";
}
.lemonicon-sigma:before {
  content: "\e9d1";
}
.lemonicon-pencil-line:before {
  content: "\e9d2";
}
.lemonicon-hand:before {
  content: "\e9d3";
}
.lemonicon-pointer-up:before {
  content: "\e9d4";
}
.lemonicon-pointer-right:before {
  content: "\e9d5";
}
.lemonicon-pointer-down:before {
  content: "\e9d6";
}
.lemonicon-pointer-left:before {
  content: "\e9d7";
}
.lemonicon-finger-tap:before {
  content: "\e9d8";
}
.lemonicon-fingers-tap:before {
  content: "\e9d9";
}
.lemonicon-reminder:before {
  content: "\e9da";
}
.lemonicon-fingers-crossed:before {
  content: "\e9db";
}
.lemonicon-fingers-victory:before {
  content: "\e9dc";
}
.lemonicon-gesture-zoom:before {
  content: "\e9dd";
}
.lemonicon-gesture-pinch:before {
  content: "\e9de";
}
.lemonicon-fingers-scroll-horizontal:before {
  content: "\e9df";
}
.lemonicon-fingers-scroll-vertical:before {
  content: "\e9e0";
}
.lemonicon-fingers-scroll-left:before {
  content: "\e9e1";
}
.lemonicon-fingers-scroll-right:before {
  content: "\e9e2";
}
.lemonicon-hand2:before {
  content: "\e9e3";
}
.lemonicon-pointer-up2:before {
  content: "\e9e4";
}
.lemonicon-pointer-right2:before {
  content: "\e9e5";
}
.lemonicon-pointer-down2:before {
  content: "\e9e6";
}
.lemonicon-pointer-left2:before {
  content: "\e9e7";
}
.lemonicon-finger-tap2:before {
  content: "\e9e8";
}
.lemonicon-fingers-tap2:before {
  content: "\e9e9";
}
.lemonicon-reminder2:before {
  content: "\e9ea";
}
.lemonicon-gesture-zoom2:before {
  content: "\e9eb";
}
.lemonicon-gesture-pinch2:before {
  content: "\e9ec";
}
.lemonicon-fingers-scroll-horizontal2:before {
  content: "\e9ed";
}
.lemonicon-fingers-scroll-vertical2:before {
  content: "\e9ee";
}
.lemonicon-fingers-scroll-left2:before {
  content: "\e9ef";
}
.lemonicon-fingers-scroll-right2:before {
  content: "\e9f0";
}
.lemonicon-fingers-scroll-vertical3:before {
  content: "\e9f1";
}
.lemonicon-border-style:before {
  content: "\e9f2";
}
.lemonicon-border-all:before {
  content: "\e9f3";
}
.lemonicon-border-outer:before {
  content: "\e9f4";
}
.lemonicon-border-inner:before {
  content: "\e9f5";
}
.lemonicon-border-top:before {
  content: "\e9f6";
}
.lemonicon-border-horizontal:before {
  content: "\e9f7";
}
.lemonicon-border-bottom:before {
  content: "\e9f8";
}
.lemonicon-border-left:before {
  content: "\e9f9";
}
.lemonicon-border-vertical:before {
  content: "\e9fa";
}
.lemonicon-border-right:before {
  content: "\e9fb";
}
.lemonicon-border-none:before {
  content: "\e9fc";
}
.lemonicon-ellipsis:before {
  content: "\e9fd";
}